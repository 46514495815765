import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})

export class LocalizationService {
  selected = '';

  constructor(private translate: TranslateService) { }

  async setInitialAppLanguage() {
    let userlanguage = await (await Storage.get({ key: "user-lang" })).value

    if (userlanguage === null) {
      userlanguage = "pt";
    }
    
    await this.translate.setDefaultLang(userlanguage);
    await this.setLanguage(userlanguage);
  }

  async setLanguage(lng) {
    this.selected = lng;
    await this.translate.use(lng);
    await Storage.set({ key: 'user-lang', value: lng });
  }
}

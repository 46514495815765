import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/signin',
    pathMatch: 'full'
  },
  {
    path: 'signin',
    loadChildren: () => import('./pages/public/signin/signin.module').then( m => m.SigninPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/secure/tabs/tabs.module').then( m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-list',
    loadChildren: () => import('./pages/secure/product-list/product-list.module').then( m => m.ProductListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-details',
    loadChildren: () => import('./pages/secure/product-details/product-details.module').then( m => m.ProductDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-details/:id',
    loadChildren: () => import('./pages/secure/product-details/product-details.module').then( m => m.ProductDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'olive-groove-details',
    loadChildren: () => import('./pages/secure/olive-groove-details/olive-groove-details.module').then( m => m.OliveGrooveDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'olive-groove-details/:id',
    loadChildren: () => import('./pages/secure/olive-groove-details/olive-groove-details.module').then( m => m.OliveGrooveDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'password-recovery',
    loadChildren: () => import('./pages/public/password-recovery/password-recovery.module').then( m => m.PasswordRecoveryPageModule)
  },
  {
    path: 'varieties-list',
    loadChildren: () => import('./pages/secure/varieties-list/varieties-list.module').then( m => m.VarietiesListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variety-details',
    loadChildren: () => import('./pages/secure/variety-details/variety-details.module').then( m => m.VarietyDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variety-details/:id',
    loadChildren: () => import('./pages/secure/variety-details/variety-details.module').then( m => m.VarietyDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'users-list',
    loadChildren: () => import('./pages/secure/users-list/users-list.module').then( m => m.UsersListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user-details',
    loadChildren: () => import('./pages/secure/user-details/user-details.module').then( m => m.UserDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'user-details/:id',
    loadChildren: () => import('./pages/secure/user-details/user-details.module').then( m => m.UserDetailsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'password-reset/:id',
    loadChildren: () => import('./pages/public/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'user-change-password',
    loadChildren: () => import('./pages/secure/user-change-password/user-change-password.module').then( m => m.UserChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./pages/secure/logs/logs.module').then( m => m.LogsPageModule),
    canLoad: [AuthGuard]
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api_url: "https://wolfvaluechainwebapi.znuni-app.ch",
  //api_url: "https://localhost:7125",
  session: {
    TOKEN_KEY: 'user-apitoken',
    user_name : "user-name",
    user_id : "user-id",
    user_email : "user-email",
    user_lang: "user-lang",
    user_imagefilename: "user-imagefilename",
    user_notifications : "user-hasnotificationsavailable",
    user_isadmin : "user-isadmin"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from } from 'rxjs';
import { Preferences } from '@capacitor/preferences';


import { environment } from 'src/environments/environment';
import { UtilsService } from 'src/app/utils/utils.service';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  // Init with null to filter out the first value in a guard!
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  url = environment.api_url;
  session = environment.session;
  headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  }

  constructor(private http: HttpClient, private utilsService: UtilsService) {
    this.loadToken();
  }

  async loadToken() {
    const token = await Preferences.get({ key: this.session.TOKEN_KEY });

    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    }
    else {
      if (token.value) {
        this.utilsService.presentToast("Por favor faça login...")
      }

      this.isAuthenticated.next(false);
    }
  }

  login(credentials) {
    credentials.signInType = 1;

    return this.http.post(`${this.url}/api/authentication/signin`, credentials, this.headers).pipe(
      map((data: any) => data),
      switchMap(async data => {
        await Preferences.set({ key: this.session.user_id, value: data.userId });
        await Preferences.set({ key: this.session.user_name, value: data.name });
        await Preferences.set({ key: this.session.user_lang, value: data.language });
        await Preferences.set({ key: this.session.user_email, value: data.email });
        await Preferences.set({ key: this.session.user_imagefilename, value: data.imageFileName });
        await Preferences.set({ key: this.session.user_notifications, value: data.hasNotificationsAvailable });
        await Preferences.set({ key: this.session.user_isadmin, value: data.isAdmin });

        return from(Preferences.set({ key: this.session.TOKEN_KEY, value: data.token }));
      }),
      tap(_ => {
        this.isAuthenticated.next(true);
      })
    )
  }

  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    return Preferences.remove({ key: this.session.TOKEN_KEY });
  }

  recoverypassword(email) {
    return this.http.post(`${this.url}/api/authentication/recoveryPassword`, { email: email });
  }

  resetpassword(model) {
    model.code = model.code.toString();
    return this.http.post(`${this.url}/api/authentication/resetPassword`, model);
  }
}
import { Injectable } from '@angular/core';
import { AlertController, Platform, ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})

export class UtilsService {
  constructor(
    private platform: Platform,
    private toastController: ToastController,
    public alertController: AlertController) { }

  async presentSuccessToast(message) {
    await this.presentToast(message, 'success');
  }

  async presentErrorToast(message, type = "normal") {
    await this.presentToast(message, 'error');
  }

  async presentToast(message, type = "normal") {
    let color = "tertiary";
    let icon = "information";

    switch (type) {
      case 'success':
        color = "success";
        icon = 'checkmark-outline';
        break;
      case 'error':
        color = "danger";
        icon = 'warning-outline';
        break;
    }

    const toast = await this.toastController.create({
      message: message,
      duration: 4000,
      color: color,
      icon: icon
    });
    await toast.present();
  }

  async presentOkAlert(header, message, okhandler = () => { }) {
    const alert = await this.alertController.create({
      cssClass: 'my-custom-class',
      header: header,
      message: message,
      mode: 'ios',
      backdropDismiss: false,
      buttons: [
        {
          text: 'Ok',
          handler: () => {
            okhandler();
          }
        }
      ]
    });

    await alert.present();
  }

  isMobile() {
    return this.platform.is('ios') || this.platform.is('android');
  }

  dataURLtoFile(dataurl, filename) {
    let arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }

  resizeImage(file, callback, maxW=1080, maxH=1080) {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    var img = document.createElement('img');

    let t = this;
    img.onload = function() {
      var iw = img.width;
      var ih = img.height;

      if(iw < maxW || ih < maxH){
        maxW = iw;
        maxH = ih;
      }
      var scale = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scale;
      var ihScaled = ih * scale;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      context.drawImage(img, 0, 0, iwScaled, ihScaled);

      var result = {
        fullPath: canvas.toDataURL(),
        file:  t.dataURLtoFile(canvas.toDataURL(), file.name)
      }
      
      callback(result);
    }

    img.src = URL.createObjectURL(file);
  }
}
import { Injectable } from '@angular/core';
import { filter, interval, map } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { TranslateService } from '@ngx-translate/core';
import { UtilsService } from 'src/app/utils/utils.service';

@Injectable({
  providedIn: 'root'
})

export class SwupdaterService {

  constructor(public updates: SwUpdate, private utilsService : UtilsService, private translateService : TranslateService) {
    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate().then(() => console.log('checking for updates')));
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.
      pipe(filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
        map(evt => ({
          type: 'UPDATE_AVAILABLE',
          current: evt.currentVersion,
          available: evt.latestVersion,
        }))).subscribe(evt => {
          this.promptUser();
        });
  }

  private promptUser(): void {
    this.updates.activateUpdate().then(async (res) => { 
      await this.utilsService.presentToast("Updating...");
      document.location.reload(); 
    });
  }
}

import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Storage } from '@capacitor/storage';
import { from } from 'rxjs';

const TOKEN_KEY = 'user-apitoken';

@Injectable({
  providedIn: 'root'
})
export class HttpinterceptorService implements HttpInterceptor {
  token: string;

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return from(this.handle(req, next))
  }

  async handle(req: HttpRequest<any>, next: HttpHandler) {
    const token = await Storage.get({ key: TOKEN_KEY });

    if (req.url.includes('auth/signin')) {
      return next.handle(req).toPromise();
    }

    const authReq = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + token.value
      }
    })

    return next.handle(authReq).toPromise()
  }
}
import { Component } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LocalizationService } from './services/localization/localization.service';
import { SwupdaterService } from './services/updates/swupdater.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    private sw: SwupdaterService,
    private localizationService: LocalizationService,) { }

  async ngOnInit() {
    await this.initializeApp();
  }

  async initializeApp() {
    await this.platform.ready();
    this.sw.checkForUpdates();
    await this.localizationService.setInitialAppLanguage();
  }
}
